/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cancel_2': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<mask id="svgicon_cancel_2_a" maskUnits="userSpaceOnUse" x="1" y="2" width="16" height="15"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.536 12.606a1 1 0 000-1.414l-2.122-2.121 2.121-2.121a1 1 0 00-1.414-1.415l-2.12 2.121-2.122-2.12a1 1 0 00-1.414 1.413l2.12 2.122-2.12 2.121a1 1 0 101.414 1.414L9 10.485l2.122 2.121a1 1 0 001.414 0z" _fill="#343443"/></mask><g mask="url(#svgicon_cancel_2_a)"><path pid="1" _fill="#fff" d="M0 0h18v18H0z"/></g>'
  }
})
